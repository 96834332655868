/**
 * Footer
 */

footer {
    background-color: $gray-800;
    color: $white;
    font-size: 0.875rem;
    text-transform: uppercase;
    .footer-top {
        padding: 25px 0 15px;
        .nav {
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
            li {
                @include media-breakpoint-up(md) {
                    margin-left: 15px;
                    border-left: 1px solid $white;
                    padding-left: 15px;
                    line-height: normal;
                    &:first-of-type {
                        margin-left: 0;
                        border-left: 0;
                        padding-left: 0;
                    }
                }
                @include media-breakpoint-up(lg) {
                    margin-left: 20px;
                    padding-left: 20px;
                }
            }
            a {
                display: block;
                color: $white;
            }
        }
    }
    .socket {
        border-top: 1px solid $white;
        padding: 15px 0;
    }
}