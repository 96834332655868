/**
 * General styles
 */

* {
    :focus {
        outline: none !important;
    }
}

.heading {
    font-family: $font-family-serif;
    &.big {
        color: $gray-700;
        font-size: 2.25rem;
        line-height: normal;
        @include media-breakpoint-up(md) {
            font-size: 3.75rem;
        }
    }
    &.medium {
        color: $gray-700;
        font-size: 2.25rem;
        line-height: normal;
        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }
    &.small {
        color: $gray-700;
        font-size: 1.5rem;
        line-height: normal;
    }
    &.smaller {
        color: $gray-700;
        font-size: 1.25rem;
        line-height: normal;
    }
}

.row {
    &.row-5 {
        margin: 0 !important;
        > div {
            padding: 0 5px !important;
        }
    }
}

.btn {
    &-white {
        background-color: $white;
        color: $gray-600;
        border-color: $white;
    }
    &-custom1 {
        padding-top: 16px;
        padding-bottom: 16px;
        min-width: 230px;
        text-transform: uppercase;
    }
    &-outline-custom1 {
        border: $border-width solid $gray-700;
        &:hover {
            color: $white;
            background-color: $gray-700;
        }
    }
    &-outline-custom2 {
        color: $white;
        border: $border-width solid $white;
        &:hover {
            color: $gray-700;
            background-color: $white;
        }
    }
    &-menu {
        margin-left: auto;
        border: 0;
        padding: 0;
        line-height: 0;
        background: transparent;
        &:after {
            content: "";
            width: 30px;
            height: 30px;
            background: url(../img/icons/menu.png) center/contain;
            display: inline-block;
            vertical-align: top;
            filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(100%);
        }
    }
    &-close {
        border: 0;
        padding: 0;
        line-height: 0;
        background: transparent;
        &:after {
            content: "";
            width: 30px;
            height: 30px;
            background: url(../img/icons/cross.png) center/contain;
            display: inline-block;
            vertical-align: top;
        }
    }
    &-envelope {
        border: 0;
        padding: 0;
        line-height: 0;
        background: transparent;
        &:after {
            content: "";
            width: 50px;
            height: 35px;
            background: url(../img/icons/envelope.png) center/contain;
            display: inline-block;
            vertical-align: top;
        }
    }
    &-booking {
        border-radius: 10px;
        padding: 8px 20px;
        color: $white;
    }
}

.bg-200 {
    background-color: $gray-200 !important;
}

.social.circles {
    padding: 10px 0 15px;
    text-align: center;
    li {
        margin-left: 5px;
        &:first-of-type {
            margin-left: 0;
        }
    }
    a {
        border-radius: 99px;
        width: 35px;
        height: 35px;
        display: block;
        background: $gray-600;
        color: $white;
        font-size: 1.125rem;
        line-height: 35px;
    }
}

p i {
    font-family: $font-family-serif;
}

.box1 {
    padding: 0 25px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    box-shadow: 0 0 50px rgba($gray-600, 1);
    overflow: hidden;
    
    img {
        margin: 0 -25px 30px;
        max-width: calc(100% + 50px);
    }
    .heading {
        margin-bottom: 30px;
        color: #444;
        font-size: 1.25rem;
        font-weight: bold;
    }
    .dsc {
        margin-bottom: auto;
    }
    a {
        margin-top: 25px;
        color: $primary;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.box2 {
    position: relative;
    background-color: $gray-200;
    overflow: hidden;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background: rgba($secondary, .6);
    }
    
    img {
        object-fit: cover;
        width: 100%;
        height: 455px;
        transition: 0.4s ease-in-out;
        min-height: 250px;
    }

    .box-content {
        position: absolute;
        z-index: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .heading {
        color: $white;
        font-size: 2.25rem;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.22em;
        text-decoration: none;
        text-transform: uppercase;
    }
    p {
        font-size: 1.125rem;
    }
}

.zoom {
    img {
        transition: 0.4s;
    }
    &:hover {
        img {
            transform: scale(1.01);
            z-index: 2;
        }
    }
}

.modal-header {
    border-bottom: 2px solid $gray-400;
    .heading {
        font-size: 1.25rem;
        line-height: 1.5;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.modal-backdrop + .modal-backdrop {
    z-index: 1060;
}

article {
    .heading {
        margin-bottom: 15px;
    }
    .row {
        justify-content: center;
    }
    .row img {
        margin-bottom: 1rem;
    }
}

.list-custom1 {
    padding: 0;
    list-style: none;
    li {
        margin-bottom: 1rem;
        a,
        a:hover {
            padding: 7px 30px;
            display: block;
            color: $white;
            background-color: $gray-800;
            font-size: 1.5rem;
            text-decoration: none;
        }
    }
}

p {
    &.column2 {
        column-gap: 30px;
        @include media-breakpoint-up(lg) {
            column-count: 2;
        }
    }
}

@keyframes zoomin {
    0% {
      transform: scale(1) translateY(0);
      transform-origin: 50% 50%;
    }
    100% {
      transform: scale(1.10) translateY(0);
      transform-origin: 50% 50%;
    }
}

.position-sticky.top-nav {
    left: 0;
    top: 120px;
}

.modal-open {
    @include media-breakpoint-down(lg) {
        //Iphone, Ipads fixer
    
        left: 0;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        width: 100%;
    }
}

.flag-fr{
    width: 2.2rem;
}
.home_popup {
    position: fixed;
    bottom: 0;
    right: 1rem;
    z-index: 1000;
    img {
        max-height: 280px;
        @include media-breakpoint-down(md) {
            max-height: 180px;
        }
    }
    .btn-close-popup {
        background: transparent;
        position: absolute;
        top: -20px;
        right: 1rem;
        z-index: 100;
        img {
            max-height: 25px;
        }
    }
}