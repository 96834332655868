/**
 * Header styles
 */

.menu-top {
    background-color: transparent;
    transition: background-color 0.3s;
    padding: 0 15px;

    @include media-breakpoint-down(md) {
        background-color: rgba(0,0,0,.7);
    }

    .row {
        transition: 0.3s;
        
        padding: 5px 0;
        @include media-breakpoint-up(md) {
            padding: 15px 0;
        }
    }
    .navbar-nav {
        @include media-breakpoint-down(lg) {
            font-size: 0.875rem
        }
       
        > li > a {
            text-shadow: 0px 5px 12px #000;
        }

        a {
            padding: 0;
            color: $white;
            line-height: 30px;
            text-transform: uppercase;
            font-size: 0.75rem;
            
            @include media-breakpoint-up(xl) {
                font-size: .93rem;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 1rem;
            }
        }
    }
    .navbar-brand {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 0;
        transition: 0.3s;

        // logo base on width
        // max-width: 160px;
        // @include media-breakpoint-up(xl) {
        //     max-width: 250px;
        // }
        // @include media-breakpoint-up(xxl) {
        //     max-width: 330px;
        // }
        // img {
        //     max-width: 100%;
        //     max-height: 100%;
        // }

        // logo base on height
        img {
            max-height: 40px;
            transition: 0.3s;
            @include media-breakpoint-up(xl) {
                max-height: 60px;
            }
            @include media-breakpoint-up(xxl) {
                max-height: 80px;
            }
        }
    }
    &.scroll {
        background-color: rgba($black, 0.7);
        
        .navbar-brand {
            img {
                max-height: 40px;
            }
        }
    }
    [href="/contact"] {
        &:after {
            content: "";
            margin-left: 5px;
            width: 30px;
            height: 30px;
            background: url(../img/icons/phone.png) center/contain;
            display: inline-block;
            vertical-align: top;
            transform: rotate(-25deg);
            filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(100%);
        }
    }
    .dropdown-menu {
        background: rgba(0,0,0,.7);
        margin: 10px 0;
        padding: 0;
        position: static !important;
        transform: translate3d(0px, 0px, 0px) !important;

        @include media-breakpoint-up(lg) {
            position: absolute !important;
            top: 50px !important;
            min-width: 250px;
        }

        li {
            margin: 0;
        }

        a {
            padding: .6rem 1rem;
            
            @include media-breakpoint-up(md) {
                padding: .6rem .7rem;
            }
        }
    }
}

.contact {
    z-index: 1070;
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
    .modal-content {
        border: 0;
        border-radius: 0;
        // padding: 40px 0;
        height: 100%;
        background-color: transparent;
        overflow-y: auto;
    }
    .modal-header {
        margin-bottom: 40px;
        border: 0;
        padding: 40px 0 0;
        filter: invert(100%) sepia(97%) saturate(0) hue-rotate(354deg) brightness(103%) contrast(100%);
    }
    .modal-body {
        padding: 0 0 40px;
    }
    .logo {
        margin: 0 auto;
        max-width: 320px;
        display: block;
    }
    .heading {
        font-size: 2.25rem;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.22em;
        @include media-breakpoint-up(md) {
            font-size: 3.75rem;
        }
    }
}
