/**
 * Variables
 * 
 * Add any variables needed. Bootstrap variables can be also overwritten here accodring to the document below:
 * @see https://getbootstrap.com/docs/4.3/getting-started/theming/#variable-defaults
 */

// Color system - 6

$white:    #ffffff;
$gray-200: #efefef;
$gray-400: #cccccc;
$gray-500: #aaaaaa;
$gray-600: #636363;
$gray-700: #555555;
$gray-800: #363636;
$gray-900: #222222;

$primary:        #850057;
$secondary:        #84754e;
$success:         #7cc576;

// Spacing - 124

$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    5: 5px,
    10: 10px,
    15: 15px,
    20: 20px,
    25: 25px,
    30: 30px,
    35: 35px,
    40: 40px,
    50: 50px,
    70: 70px,
   100: 100px,
  ),
  $spacers
);

// Body - 160

$body-bg:        $white;
$body-color:     $gray-600;

// Grid breakpoints - 186

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
) !default;
  
// Grid containers - 203

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1560px
) !default;

// Components - 225

$border-radius:               0;

$border-width:                2px;

// Buttons - 379

$btn-line-height: 1.5;

$btn-font-weight:             600;
$btn-focus-width:             none;
$btn-focus-box-shadow:        none;

// Typography - 268

@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Playfair+Display:400,400i,700,700i');

$font-family-sans-serif: 'Lato', sans-serif;
$font-family-serif: 'Playfair Display', serif;

$line-height-base:            1.875;

// Forms - 447

$input-focus-box-shadow:      none;

// Pagination - 780

$pagination-color: $gray-600;

// Modals - 927

$modal-dialog-margin: 1rem;

$modal-backdrop-bg: $gray-900;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;

$modal-backdrop-opacity:            0.95;





// FontAwesome

$fa-font-path: "../fonts/fontawesome";

@import 'fontawesome/variables';
@import 'fontawesome/mixins';
@import 'fontawesome/core';
// @import 'fontawesome/larger';
// @import 'fontawesome/fixed-width';
// @import 'fontawesome/list';
// @import 'fontawesome/bordered-pulled';
// @import 'fontawesome/animated';
// @import 'fontawesome/rotated-flipped';
// @import 'fontawesome/stacked';
@import 'fontawesome/icons';
// @import 'fontawesome/screen-reader';

@import "fontawesome/regular";
@import "fontawesome/solid";
@import "fontawesome/brands";

// Slick

// $slick-font-path: "../fonts/slick/" !default;
$slick-loader-path: "../img/" !default;

@import 'slick/slick';
// @import 'slick/slick-theme';

// Slick lightbox

@import 'slick-lightbox/slick-lightbox';

// Air-datapicker

@import 'air-datapicker/datapicker';