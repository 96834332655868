/**
 * Slider styles
 */

.slick-slider {
    .slick-arrow {
        position: absolute;
        top: 50%;
        border: 3px solid $white;
        padding: 0;
        height: 30px;
        width: 30px;
        display: block;
        background: 0 0;
        color: transparent;
        font-size: 0;
        transform: translate(0,-50%);
        outline: 0;
        z-index: 1;
        cursor: pointer;
        @include media-breakpoint-up(sm) {
            border: 5px solid $white;
            height: 50px;
            width: 50px;
        }

        &:before {
            content: "X";
            font-size: 14px;
            line-height: 1;
            color: #fff;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            @include media-breakpoint-up(sm) {
                font-size: 32px;
            }
        }
        &.slick-prev {
            left: 15px;
            &:before {
                content: "\f053";
            }
        }
        &.slick-next {
            right: 15px;
            &:before {
                content: "\f054";
            }
        }
    }
    .slick-dots {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block;
        text-align: center;
        list-style: none;
        li {
            position: relative;
            display: inline-block;
            margin: 0 3px;
            padding: 0;
            button {
                margin: 0;
                border: 0;
                border-radius: 99px;
                padding: 0;
                display: block;
                background-color: transparent;
                line-height: 0;
                font-size: 0;
                cursor: pointer;
                &:before {
                    border: 0;
                    border-radius: 99px;
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: block;
                    background-color: rgba($white, 0.5);
                    transition: 0.3s;
                }
            }
            &.slick-active button:before {
                background-color: rgba($white, 1);
            }
        }
    }
}

.slick-lightbox {
    .close {
        position: absolute;
        top: 10px; right: 15px;
        color: $white;
        opacity: 1;
    }
    .close:not(.disabled):hover {
        opacity: 1;
    }
}

.main-slider {
    .slide {
        position: relative;
        width: 100%;
        height: 100vh;
        min-height: 420px;
    }
    .content {
        padding: 80px 15px 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        @include media-breakpoint-up(xl) {
            padding: 100px 15px 100px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 120px 15px 100px;
        }
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .slick-current {
        // img {
        //     animation: zoomin 5s ease-out;
        // }
    }
    .heading {
        font-size: 2.25rem;
        line-height: normal;
        text-transform: uppercase;
        text-shadow: 0 5px 12px #000;
        
        @include media-breakpoint-up(lg) {
            font-size: 4.5rem;
        }
    }
    .subheading-top {
        // margin-bottom: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            // margin-bottom: 25px;
            font-size: 1.125rem;
        }
    }
    .subheading-bot {
        margin-top: 10px;
        font-size: 1.5rem;
        font-family: $font-family-serif;
        font-style: italic;
        @include media-breakpoint-up(lg) {
            margin-top: 20px;
            font-size: 1.125rem;
        }
    }
    .btn {
        margin-top: 20px;
        @include media-breakpoint-up(lg) {
            margin-top: 70px;
        }
    }
    .scroll-to {
        position: absolute;
        bottom: 100px;
        left: 50%;
        z-index: 1;
        transition: 0.3s;
        filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(100%);
        &:hover {
            bottom: 95px;
        }
        img {
            border-radius: 99px;
            width: 50px;
            height: 50px;
            transform: translateX(-50%);
            cursor: pointer;
        }
    }
}

.property-big {
    line-height: 0;
    .slide {
        position: relative;
        min-height: 280px;
        height: 100vh;
        max-height: 360px;
        @include media-breakpoint-up(sm) {
            max-height: calc(100vh - 180px);
        }
        @include media-breakpoint-up(lg) {
            min-height: 400px;
            max-height: calc(100vh - 210px);
        }
        @include media-breakpoint-up(xl) {
            min-height: 600px;
            max-height: calc(100vh - 240px);
        }
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: zoom-in;
    }
}

.property-small {
    padding: 15px 0;
    line-height: 0;
    .slick-slide {
        padding-left: 15px;
        pointer-events: none;
    }
    .slide {
        position: relative;
        cursor: pointer;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($gray-900, 0.5);
            transition: 0.3s;
        }
    }
    .slick-current {
        .slide:after {
            background: rgba($black, 0);
        }
    }
    img {
        height: 60px;
        pointer-events: auto;
        @include media-breakpoint-up(sm) {
            height: 90px;
        }
        @include media-breakpoint-up(lg) {
            height: 120px;
        }
        @include media-breakpoint-up(xl) {
            height: 150px;
        }
    }
}