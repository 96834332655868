/**
 * Forms styles
 */

.form-control {
    border: 0 solid $gray-200;
    border-radius: 0;
    padding: 10px 25px;
    min-height: 45px;
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 0.875rem;
}

.form1 {
    label {
        color: #fff;
        a {
            color: #cccccc;
        }
    }
    .btn {
        margin: 80px auto 30px;
        min-width: 135px;
        display: block;
    }
}

.form2 {
    margin-bottom: 1rem;
    padding: 30px 30px 40px;
    color: $white;
    background-color: $gray-800;
    .heading {
        margin-bottom: 25px;
        line-height: normal;
        @include media-breakpoint-up(md) {
            font-size: 1.875rem;
        }
    }
    label {
        color: #fff;
        a {
            color: #cccccc;
        }
    }
    .btn {
        margin: 25px auto 0;
        min-width: 120px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.booking {
    .form-group {
        margin-bottom: 20px;
    }
    .form-control {
        border-radius: 10px;
        background-color: $gray-200;
        font-family: $font-family-sans-serif;
        font-style: normal;
        &::placeholder {
            color: $gray-500;
        }
    }
}

.form3 {
    font-family: $font-family-serif;
    box-shadow: 0 0 50px rgba($gray-600, 1);
    font-style: italic;
    .logo {
        max-width: 200px;
    }
    .form-control {
        font-size: 1rem;
        text-align: center;
    }
    hr {
        margin: 0;
        border: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(99, 99, 99, 1) 50%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}
