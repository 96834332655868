/**
 * Sections styles
 */

.main-banner {
    position: relative;
    min-height: 380px;
    .content {
        padding: 80px 15px 50px;
        height: 100%;
        min-height: 380px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        @include media-breakpoint-up(xl) {
            padding: 100px 15px 50px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 120px 15px 50px;
        }
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .subheading-top {
        margin-bottom: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            margin-bottom: 25px;
            font-size: 1.125rem;
        }
    }
    .subheading-bot {
        margin-top: 10px;
        font-size: 1.5rem;
        font-family: $font-family-serif;
        font-style: italic;
        @include media-breakpoint-up(lg) {
            margin-top: 20px;
            font-size: 1.125rem;
        }
    }
    .btn {
        margin-top: 20px;
        @include media-breakpoint-up(lg) {
            margin-top: 70px;
        }
    }
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($black, 0.4);
    }
}

.half-content1 {
    padding: 30px 0;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }

    .content {
        padding: 0 0 0;
        
        * {
            position: relative;
            z-index: 1;
        }
    }
    .heading {
        margin-bottom: 30px;
    }
    .dsc {
        margin-bottom: 40px;
    }
    .images {
        padding: 0 15px 0;
        @include media-breakpoint-down(md) {
            margin-top: 15px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding: 0 15px 80px;
        }
        .image {
            position: relative;
            padding: 0 30px 0 0;
            @include media-breakpoint-up(sm) {
                padding: 0 110px 0 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 0 0 0;
            }
            @include media-breakpoint-up(xxl) {
                padding: 0;
            }
        }
        .image + .image {
            margin-top: -30px;
            padding: 0 0 0 30px;
            @include media-breakpoint-up(sm) {
                margin-top: -50px;
                padding: 0 0 0 110px;
            }
            @include media-breakpoint-up(lg) {
                left: 130px;
                padding: 0 0 0 0;
            }
            @include media-breakpoint-up(xxl) {
                left: 260px;
                margin-top: -120px;
                padding: 0;
                max-width: 83.33334%;
            }
        }
        img {
            box-shadow: 0 0 40px rgba($gray-600, 0.4);
        }
    }
    .bg-image {
        margin: -140px 0 0 0;
        position: relative;
        pointer-events: none;
        @include media-breakpoint-up(lg) {
            margin: -160px 0 0 0;
        }
        @include media-breakpoint-up(xl) {
            margin: -280px 0 0 0;
        }
        @include media-breakpoint-up(xxl) {
            margin: -440px 0 0 0;
        }
        img {
            width: 100%;
            height: 460px;
            object-fit: cover;
            object-position: 10% 0;
        }
    }
}

.half-content2 {
    padding-top: 80px;
    .border-custom {
        position: absolute !important;
        right: 15px;
        border: 4px solid $secondary;
        max-width: calc(100% - 30px);
        height: 100%;
        z-index: 0 !important;
        @include media-breakpoint-down(md) {
            top: 0;
            left: 15px;
        }
        @include media-breakpoint-up(lg) {
            max-width: calc(66.66667% - 30px);
        }
    }
    .content {
        padding: 40px 40px 80px;
        @include media-breakpoint-up(lg) {
            padding: 90px 40px;
        }
        * {
            position: relative;
            z-index: 1;
        }
    }
    .heading {
        margin-bottom: 30px;
    }
    .dsc {
        margin-bottom: 40px;
    }
    .images {
        margin-top: -40px;
        padding: 0 55px 100px;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding: 90px 15px;
        }

        img {
            box-shadow: 0 0 40px rgba($gray-600, 0.4);
        }
    }
    .bg-image {
        margin: -200px 0 0 0;
        position: relative;
        pointer-events: none;
        @include media-breakpoint-up(xxl) {
            margin: -260px 0 0 0;
        }
        img {
            width: 100%;
            height: 365px;
            object-fit: cover;
            object-position: 50% 0;
        }
    }
}

.half-content3 {
    border-bottom: 1px solid $gray-400;
    padding-top: 40px;
    padding-bottom: 50px;
    .content {
        padding: 25px 30px;
        background: $gray-900;
        color: $white;
    }
    .heading {
        margin-bottom: 30px;
    }
    .images {
        padding: 0 15px 0;
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding: 0 15px 0;
        }
        .image {
            position: relative;
            padding: 0 30px 0 0;
            @include media-breakpoint-up(sm) {
                padding: 0 110px 0 0;
            }
        }
        .image + .image {
            margin-top: -30px;
            padding: 0 0 0 30px;
            @include media-breakpoint-up(sm) {
                margin-top: -50px;
                padding: 0 0 0 110px;
            }
        }
    }
}
.section-testimonials {
    padding: 20px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($secondary, .6);
        z-index: 20;
    }

    .container {
        position: relative;
        z-index: 30;
    }
}
.testimonials {
    margin-top: 110px;
    margin-bottom: 90px;
    position: relative;
    font-size: 1.125rem;
    font-family: $font-family-serif;
    font-style: italic;
    text-align: center;
    p:last-of-type {
        margin: 0;
    }
    .slick-dots {
        margin-top: 80px;

        button {
            background: $white !important;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            position: relative;
            
            &::before {
                
                border-radius: 100% !important;
                background: $white !important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 20px !important;
                width: 20px !important;
            }
        }


        .slick-active {
            button {
                &::before {
                    background: $primary !important;
                }
            }
        }

    }
    .quote-marks {

        pointer-events: none;
        &:before,
            &:after {
            content: "";
            width: 90px;
            height: 90px;
            display: block;
            background: url(../img/icons/quote-primary.png) center/contain no-repeat;
            position: absolute;
        }
        &:before {
            top: 0;
            left: 0;
            transform: translate(0, -100%);
            @include media-breakpoint-up(md) {
                transform: translate(-100%, -100%);
            }
        }
        &:after {
            right: 0;
            bottom: 110px;
            transform: translate(0, 100%) rotate(180deg);
            @include media-breakpoint-up(md) {
                transform: translate(100%, 100%) rotate(180deg);
            }
        }
    }
}

.infobar {
    background-color: rgba($gray-800, 1);
    text-align: center;
    .icon {
        width: 25px;
        height: 25px;
        object-fit: contain;
        filter: invert(100%) sepia(97%) saturate(0) hue-rotate(354deg) brightness(103%) contrast(100%);
    }
    .link {
        background-color: rgba($primary, 1);
        text-transform: uppercase;
    }
    a {
        padding-top: 15px;
        padding-bottom: 15px;
        display: block;
        color: $white;
        &:hover {
            text-decoration: none;
        }
    }
    span {
        @include media-breakpoint-down(lg) {
            display: none;
        }
        &:first-of-type {
            @include media-breakpoint-up(xl) {
                margin-left: 10px;
            }
        }
    }
}

.property-heading {
    border-bottom: 1px solid $gray-400;
    padding-bottom: 25px;
    .heading {
        color: $gray-900;
    }
    .subheading {
        font-family: $font-family-serif;
        font-style: italic;
    }
    .price {
        font-size: 18px;
        font-weight: bold;
    }
}

.property-boxes1 {
    border-bottom: 1px solid $gray-400;
    padding-top: 15px;
    color: $gray-900;
    .box {
        margin-bottom: 15px;
        padding: 10px 15px;
        background: $gray-200;
        text-align: center;
    }
    .icon {
        width: 25px;
        height: 25px;
        object-fit: contain;
        filter: invert(16%) sepia(0%) saturate(12%) hue-rotate(138deg) brightness(89%) contrast(102%);
    }
    span {
        margin-left: 10px;
    }
}

.property-text {
    border-bottom: 1px solid $gray-400;
    padding-top: 30px;
    padding-bottom: 30px;
    .top {
        margin-bottom: 20px;
        color: $gray-900;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.property-boxes2 {
    border-bottom: 1px solid $gray-400;
    padding-top: 15px;
    padding-bottom: 25px;
    color: $gray-900;
    .heading {
        margin-bottom: 20px;
    }
    .box {
        padding-bottom: 15px;
        height: 100%;
    }
    span {
        padding: 15px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $gray-200;
        line-height: 1.5rem;
        text-align: center;
    }
}

.property-banner {
    padding-top: 35px;
}

.booking.nav {
    li {
        display: flex;
        align-items: center;

        &:before {
            content: "\f111\f111\f111";
            padding: 0 5px 0 10px;
            display: block;
            color: $gray-500;
            font-size: 5px;
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
            text-align: center;
            letter-spacing: 5px;
            line-height: 1;
            @include media-breakpoint-up(md) {
                content: "\f111\f111\f111\f111";
                padding: 0 10px 0 15px;
            }
        }
        &:first-of-type:before {
            display: none;
        }
    }
    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        pointer-events: none;
    }
    span {
        display: block;
    }
    .number {
        border-radius: 99px;
        width: 30px;
        height: 30px;
        background-color: rgba($gray-500, 1);
        color: $white;
        text-align: center;
    }
    .active .number {
        background-color: rgba($success, 1);
    }
    .text {
        position: relative;
        margin-left: -15px;
        border-radius: 99px;
        padding: 1px 10px 1px 25px;
        background-color: rgba($gray-200, 1);
        color: $gray-900;
        font-size: 0.75rem;
        z-index: -1;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.booking .list-group {
    border-radius: 10px;
    padding: 0 25px;
    background-color: $gray-200;
    @include media-breakpoint-up(lg) {
        padding: 20px 35px;
    }
    .row {
        border-width: 1px 0 0 0;
        border-color: $white;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        &:first-of-type {
            border-width: 0;
        }
    }
    .list-group-content {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            max-width: 480px;
        }
    }
    img {
        border-radius: 99px;
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
    .dsc {
        font-size: 0.875rem;
        line-height: 1.5;
    }
    .price {
        color: $success;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            font-size: 1.25rem;
        }
    }
    .quantity {
        display: flex;
        align-items: center;
        label {
            font-size: 0.875rem;
            display: block;
            @include media-breakpoint-up(lg) {
                margin-right: 50px;
            }
        }
    }
    .custom-select {
        margin-left: 15px;
        min-height: 40px;
        background-color: $white;
        @include media-breakpoint-up(sm) {
            margin-left: 50px;
            width: 100px;
        }
    }
}

.booking .list-group2 {
    padding: 0 25px;
    background-color: $gray-200;
    @include media-breakpoint-up(lg) {
        padding: 20px 35px;
    }
    .list-group-item {
        border-width: 0;
        border-color: $white;
        background-color: transparent;
    }
}

.booking-radius {
    border-radius: 10px;
    overflow: hidden;
}

.booking div.booking-calendar {
    border: 0;
    width: 100%;
    background-color: $gray-200;
    font-weight: bold;
    font-family: $font-family-sans-serif;
    @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
    }
    .datepicker--nav {
        border-bottom: 1px solid $gray-400;
        padding: 10px 15px;
    }
    .datepicker--content {
        padding: 0;
    }
    .datepicker--day-name {
        color: $gray-500;
        @include media-breakpoint-up(sm) {
            font-size: 1.25rem;
        }
    }
    .-other-month-,
    .-other-decade- {
        color: $gray-400;
    }
    .-focus- {
        background: $white;;
    }
    .datepicker--cell-day {
        margin: 5px 0;
        @include media-breakpoint-up(sm) {
            margin: 10px 0;
        }
    }
    .datepicker--cell.-current- {
        color: $primary;
    }
    .-range-from- {
        border-radius: 99px 0 0 99px;
    }
    .-range-to- {
        border-radius: 0 99px 99px 0;
    }
    .-range-from-.-range-to- {
        border-radius: 99px;
    }
    .-range-from-,
    .-in-range-,
    .-range-to- {
        background-color: $success;
        color: $white;
    }
}

.booking .summary-calendar {
    padding: 25px;

    display: flex;
    flex-direction: column;

    background-color: $gray-800;
    color: $white;

    font-size: 0.875rem;
    line-height: 1.5;
    @include media-breakpoint-up(md) {
        padding: 35px;
    }
    .heading {
        margin-bottom: 20px;
        color: $white;
    }
    .small {
        font-size: 0.875rem;
    }
    .big {
        font-size: 1.25rem;
    }
    .availability {
        margin-top: auto;
        border-radius: 99px;
        padding: 5px 15px;
        font-size: 1.25rem;
        line-height: normal;
        text-align: center;
    }
}

.booking .summary {
    padding: 25px;
    background-color: $gray-800;
    color: $white;
    font-size: 0.875rem;
    line-height: 1.5;
    @include media-breakpoint-up(md) {
        padding: 35px;
    }
    .heading {
        margin-bottom: 20px;
        color: $white;
    }
}

.booking .payment {
    padding: 25px;
    background-color: $gray-200;
    @include media-breakpoint-up(md) {
        padding: 35px;
    }
    .heading {
        margin-bottom: 20px;
    }
    p {
        font-size: 0.875rem;
        line-height: 1.5;
    }
    label {
        font-size: 0.875rem;
    }
    .form-control {
        background-color: $white;
    }
    .card {
        min-width: 216px;
        max-width: 216px;
        @include media-breakpoint-down(md) {
            border-radius: 10px;
            margin-bottom: 15px;
            min-width: 100%;
        }
    }
    .mmcc {
        min-width: 100px;
        max-width: 100px;
        @include media-breakpoint-down(md) {
            border-radius: 10px;
        }
    }
    .cvc {
        min-width: 83px;
        max-width: 83px;
        @include media-breakpoint-down(md) {
            margin-left: 15px;
            border-radius: 10px;
        }
    }
}
.prices-table{
    width:100%;
    border: 2px solid #636363;
    tr{
        td{
            width:33%;
        }
    }
}

.mb-0 {
    margin-bottom: 0;
}

.section-contact {
    background-color: rgba(#222, 0.95);
    padding: 20px 0;

    @include media-breakpoint-up(md) {
        padding: 40px 0;
    }
    
    @include media-breakpoint-up(xl) {
        padding: 60px 0;
    }

    @include media-breakpoint-up(xxl) {
        padding: 80px 0;
    }

    .content-text {
        max-width: 600px;
    }
    .form1 {
        .btn {
            margin-top: 30px;
        }
    }
}

.content-text {
    color: $white;

    h1, h2 {
        color: $white;
        font-size: 3rem;
    
        &:first-child {
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 30px;
        line-height: 1.75;
    }


}